
@media (min-width: 1280px){
    .subscribeClipPath{
        clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
    }
}


.subscribeBlur{
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: #2a256090;
}