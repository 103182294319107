@import "geometria.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #1a1b26;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bg-blur-color{
  background-color: #2a256090;
}